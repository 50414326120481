import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import APICommunication from '../../api-communication/APICommunication';
import NavBar from '../../components/NavBar';
import Form from 'react-bootstrap/Form';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function EditListing() {
    const [listings, setListings] = useState(null);
    const [newPrices, setNewPrices] = useState({});
    const [message, setMessage] = useState(null);

    const apiCommunication = new APICommunication(API_BASE_URL);

    const company_name = sessionStorage.getItem("company_name");

    const fetchData = async () => {
        try {
            const res = await apiCommunication.doCall('/company-agent/get-listings', 'GET');
            const jsonResponse = await res.json();
            setListings(jsonResponse.listings);
        } catch (e) {
            setListings(`Oops! An error occurred: ${e}`);
        }
    };

    const handleEditListing = async (listingId) => {
        const payload = {
            listing_id: listingId,
            new_price: newPrices[listingId],
        };

        try {
            const res = await apiCommunication.doCall('/company-agent/edit-listing', 'POST', payload);
            const jsonResponse = await res.json();
            setMessage(jsonResponse.message);

            if (jsonResponse.status === 'success') {
                fetchData();
                setNewPrices(prevNewPrices => {
                    return { ...prevNewPrices, [listingId]: '' };
                });
            }
        } catch (e) {
            setMessage(`Oops! An error occurred: ${e}`);
        }
    };

    const handleDeleteListing = async (listingId) => {
        const payload = {
            listing_id: listingId,
        };

        try {
            const res = await apiCommunication.doCall('/company-agent/delete-listing', 'POST', payload);
            const jsonResponse = await res.json();
            setMessage(jsonResponse.message);

            if (jsonResponse.status === 'success') {
                fetchData();
            }
        } catch (e) {
            setMessage(`Oops! An error occurred: ${e}`);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Container style={{ marginTop: '20px' }}>
                <NavBar />
            </Container>
            <div className="container" style={{ textAlign: 'center' }}>
                <div className="jumbotron" style={{ margin: '20px', padding: '20px', backgroundColor: '#f2f2f2' }}>
                    <h3 className="display-3">Edit Listings</h3>
                    {message && <p>{message}</p>}
                </div>
            </div>
            <div style={{ margin: '0 auto', width: '80%' }}>
                <table style={{
                    width: '100%',
                    margin: '20px auto',
                    borderCollapse: 'collapse',
                    textAlign: 'center'
                }}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Current Price (&#xa2;)</th>
                            <th>Type</th>
                            <th>New Price (&#xa2;)</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listings && listings.map((listing, index) => (
                            <tr key={index}>
                                <td>{listing.name}</td>
                                <td>{listing.current_price}</td>
                                <td>{listing.type}</td>
                                <td>
                                    <Form>
                                        <div className="d-flex justify-content-center ">
                                    <Form.Label></Form.Label>
                                    <Form.Control
                                        type="number"
                                        min='1'
                                        step='1'
                                        placeholder='1'
                                        value={newPrices[listing.id] || ''}  // Use listing.id instead of listing.listing_id
                                        onChange={(e) => setNewPrices({
                                            ...newPrices,
                                            [listing.id]: e.target.value,  // Use listing.id instead of listing.listing_id
                                        })}
                                        style={{ width: '120px' }}
                                    />
                                    </div>
                                    </Form>
                                </td>
                                <td>
                                    <button onClick={() => handleEditListing(listing.id)} className="btn btn-primary">Edit</button>  {/* Use listing.id */}
                                    <button onClick={() => handleDeleteListing(listing.id) } className="btn btn-danger">Delete</button>  {/* Use listing.id */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default EditListing;
