import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import APICommunication from '../../api-communication/APICommunication';
import NavBar from '../../components/NavBar';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Portfolio() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const user = sessionStorage.getItem("name");

    const apiCommunication = new APICommunication(API_BASE_URL);

    const fetchData = async () => {
        setLoading(true);
        setData(null);
        try {
            const res = await apiCommunication.doCall('/customer/get-portfolio', 'GET');
            const jsonResponse = await res.json();

            setData(jsonResponse.portfolio);
        } catch (e) {
            setData(`Oops! An error occurred: ${e}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Container style={{ marginTop: '20px' }}>
                <NavBar />
            </Container>
            <div className="container" style={{ textAlign: 'center' }}>
                <div className="jumbotron" style={{ margin: '20px', padding: '20px', backgroundColor: '#f2f2f2' }}>
                    <h3 className="display-3">{`${user}'s Portfolio`}</h3>
                    {data && (
                        <div>
                            <p><strong>Standing: </strong>${(data.standing/100).toFixed(2)}</p>
                            <p><strong>Total: </strong> ${(data.total / 100).toFixed(2)}</p>
                        </div>
                    )}
                </div>
            </div>
            <div style={{ margin: '0 auto', width: '80%' }}>
                <table style={{
                    width: '100%',
                    margin: '20px auto',
                    borderCollapse: 'collapse',
                    textAlign: 'center'
                }}>
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Company</th>
                            <th scope="col">Purchase Price</th>
                            <th scope="col">Current Price</th>
                            <th scope="col">Shares Owned</th>
                            <th scope="col">Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.shares.map((share, index) =>
                            <tr key={index}>
                                <td>{share.name}</td>
                                <td>{share.company}</td>
                                <td>${(share.purchase_price / 100).toFixed(2)}</td>
                                <td>${(share.current_price / 100).toFixed(2)}</td>
                                <td>{share.number}</td>
                                <td>{share.type}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Portfolio;