import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function ForgotPassword() {
    const [state, setState] = useState({ email: "", showLink: false });
    const navigate = useNavigate();

    async function handleForgotPassword(e) {
        e.preventDefault();

        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());

        const response = await fetch(`${API_BASE_URL}/forgot-password`, {
            method: form.method, body: JSON.stringify(formJson)
            // method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },
            // body: JSON.stringify(formJson)
        });

        const result = await response.json();

        if (result.status === 'success') {
            setState({ ...state, showLink: true });
        } else {
            alert(result.message || 'Failed to reset password.');
        }
    }

    return (
        <>
            <button><Link to="/">Home</Link></button>
            <section>
                <form method="post" onSubmit={handleForgotPassword}>
                    <h2>Forgot Password</h2>
                    <p>
                        <label htmlFor="email"><span>Email:</span></label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={state.email}
                            onChange={(e) => { setState({ ...state, email: e.target.value }) }}
                        />
                    </p>
                    <p>
                        <input type="submit" value="Send Reset Link" />
                    </p>
                    {state.showLink && (
                        <p>
                            <Link to={`/change-password?email=${state.email}`}>Proceed to change password</Link>
                        </p>
                    )}
                    <p>
                        <Link to="/login">Back to Login</Link>
                    </p>
                </form>
            </section>
        </>
    );
}

export default ForgotPassword;
