import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoginNavigation from "../components/LoginNavigation"
import PageFooter from 'components/PageFooter';
import Navigation from 'components/Navigation';
import Form from 'react-bootstrap/Form';
import APICommunication from '../api-communication/APICommunication';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const api = new APICommunication(API_BASE_URL);

function Login() {
    const navigate = useNavigate();
    const [state, setState] = useState({ username: "", password: "" });

    async function handleSubmit(e) {
        e.preventDefault();
        const { username, password } = state;

        try {
            const result = await api.login(username, password);

            if (result.status === 'success') {
                navigate("/" + result.role.toLowerCase());
            } else {
                alert("Login failed! " + result.reason);
            }
        } catch (e) {
            //debugging
            console.log('Error during login:', e);
            console.error(e.stack);
            alert("Login Failed! Wrong username or password.");
        }
    }

    var tabs = [{ path: "/", name: "Home" },
    { path: "/admin", name: "Admin" },
    { path: "/customer", name: "Customer" },
    { path: "/agent", name: "Agent" }]

    return (
        <>
            <Container>
                <Row>
                    <Navigation />
                </Row>
                <Row>
                    <div style={{height: '150px'}}></div>
                    <section className="d-flex justify-content-center m-4">
                        <Form method="post" onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" placeholder="Enter Username"
                                    value={state.username} onChange={
                                        (e) => { setState(oldState => ({ ...oldState, "username": e.target.value })) }
                                    } />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password"
                                    value={state.password}
                                    onChange={
                                        (e) => { setState(oldState => ({ ...oldState, "password": e.target.value })) }
                                    } />
                            </Form.Group>
                            <div className="d-flex justify-content-center">
                                <input type="submit" value="Login" className="btn btn-primary" />
                            </div>
                        </Form>
                    </section>
                </Row>
                <Row>
                    <PageFooter />
                </Row>
            </Container>
        </>
    );
}

export default Login;
