import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import APICommunication from '../api-communication/APICommunication';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


function Navigation() {
    const navigate = useNavigate();
    const role = sessionStorage.getItem("role");
    const api = new APICommunication(API_BASE_URL);

    const [isLoggingOut, setIsLoggingOut] = useState(false);

    const handleLogout = async () => {
        setIsLoggingOut(true);
        try {
            await api.logout();
        } catch (error) {
            console.error("Error during logout:", error);
        } finally {
            sessionStorage.clear();
            setIsLoggingOut(false);
            navigate('/');
        }
    };

    let tabs;

    if (role === 'CUSTOMER') {
        tabs = [
            { path: "/customer", name: "Home", callback: () => { } },
            { path: "/customer/buy", name: "Buy", callback: () => { } },
            { path: "/customer/portfolio", name: "Portfolio", callback: () => { } },
            { path: "/customer/sell", name: "Sell", callback: () => { } },
            { path: "/", name: "Logout", callback: () => { } }
        ];
    } else if (role === 'COMPANY_AGENT') {
        tabs = [
            { path: "/company_agent", name: "Home", callback: () => { } },
            { path: "/company/view-listings", name: "View Your Listings", callback: () => { } },
            { path: "/company/create-listing", name: "Create New Listings", callback: () => { } },
            { path: "/company/edit-listing", name: "Edit Listings", callback: () => { } },
            { path: "/", name: "Logout", callback: () => { } }
        ];
    } else {
        // Default tabs if userType is unknown
        tabs = [
            { path: "/admin", name: "Home", callback: () => { } },
            { path: "/admin/view-customers", name: "View Customers", callback: () => { } },
            { path: "/admin/view-companies", name: "View Companies", callback: () => { } },
            { path: "/admin/edit-companies", name: "Edit Companies", callback: () => { } },
            { path: "/", name: "Logout", callback: () => { } }
        ];
    }

    // Replace the callback for the Logout tab to handleLogout function
    tabs[tabs.length - 1].callback = handleLogout;

    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand href="#home" style={{ padding: "10px" }}>Cloud Trading</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    {tabs.map((tab, index) => (
                        <Nav.Link
                            key={index}
                            disabled={isLoggingOut && tab.name === 'Logout'}
                            onClick={() => {
                                tab.callback();
                                navigate(tab.path);
                            }}
                        >
                            {tab.name}
                        </Nav.Link>
                    ))}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Navigation;