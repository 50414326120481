import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import APICommunication from '../../api-communication/APICommunication';
import NavBar from '../../components/NavBar';
import Form from "react-bootstrap/Form";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function SellShare() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [quantities, setQuantities] = useState({});
    const [message, setMessage] = useState(null);

    const apiCommunication = new APICommunication(API_BASE_URL);

    const fetchData = async () => {
        setLoading(true);
        try {
            const res = await apiCommunication.doCall('/customer/get-portfolio', 'GET');
            const jsonResponse = await res.json();
            setData(jsonResponse.portfolio);
        } catch (e) {
            setData(`Oops! An error occurred: ${e}`);
        } finally {
            setLoading(false);
        }
    };

    const handleSellShare = async (shareId) => {
        const payload = {
            share_id: shareId,
            quantity: quantities[shareId],
        };

        try {
            const res = await apiCommunication.doCall('/customer/sell-share', 'POST', payload);
            const jsonResponse = await res.json();
            setMessage(jsonResponse.message);

            if (jsonResponse.status === 'success') {
                setQuantities(prevQuantities => {
                    const newQuantities = { ...prevQuantities };
                    delete newQuantities[shareId];
                    return newQuantities;
                });
                fetchData();
            }
        } catch (e) {
            setMessage(`Oops! An error occurred: ${e}`);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Container style={{ marginTop: '20px' }}>
                <NavBar />
            </Container>
            <div className="container" style={{ textAlign: 'center' }}>
                <div className="jumbotron" style={{ margin: '20px', padding: '20px', backgroundColor: '#f2f2f2' }}>
                    <h3 className="display-3">Sell Shares</h3>
                    {message && <p>{message}</p>}
                </div>
            </div>
            <div style={{ margin: '0 auto', width: '80%' }}>
                <table style={{
                    width: '100%',
                    margin: '20px auto',
                    borderCollapse: 'collapse',
                    textAlign: 'center'
                }}>
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Current Price</th>
                            <th scope="col">Shares Owned</th>
                            <th scope="col">Type</th>
                            <th scope="col">Quantity to Sell</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.shares.map((share, index) => (
                            <tr key={index}>
                                <td>{share.name}</td>
                                <td>${(share.current_price/100).toFixed(2)}</td>
                                <td>{share.number}</td>
                                <td>{share.type}</td>
                                <td>
                                    <div className="d-flex justify-content-center">
                                    <Form.Control
                                        type="number"
                                        min='1'
                                        step='1'
                                        max={share.number}
                                        placeholder='1'
                                        value={quantities[share.share_id] || ''}
                                        onChange={(e) => {
                                            if (e.target.value <= share.number){
                                                setQuantities({
                                                ...quantities,
                                                [share.share_id]: e.target.value,
                                                }
                                            )}
                                        }}
                                        style={{ width: '120px' }}  // Make the text box smaller
                                    />
                                    </div>
                                </td>
                                <td>
                                    <button className="btn btn-primary" onClick={() => handleSellShare(share.share_id)}>Sell</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default SellShare;