// HomePage.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
//import '../App.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import PageFooter from 'components/PageFooter';
import NavBar from 'components/NavBar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import APICommunication from '../../api-communication/APICommunication';


function CompanyAgentHomePage() {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState(null)
  const [loading, setLoading] = useState(false);
  const [listings, setListings] = useState([]);
  const [company, setCompany] = useState(null);
  const [showInfo, setShowInfo] = useState('');

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const apiCommunication = new APICommunication(API_BASE_URL);

  const name = sessionStorage.getItem("name");
  const company_name = sessionStorage.getItem("company_name");

  const navigate = useNavigate(); // For navigation
  const location = useLocation();

  const fetchCompanyInfo = async () => {
    setLoading(true);
    try {
      const res = await apiCommunication.doCall('/company-agent/get-company', 'GET'); // Replace 'your-company-info-endpoint' with the actual endpoint
      const jsonResponse = await res.json();
      console.log(jsonResponse);
      setCompany({
        id: jsonResponse.company_id,
        name: jsonResponse.name,
        industry: jsonResponse.industry
      });
      setStatus(null);
    } catch (e) {
      setStatus(`Error fetching company information: ${e}`);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchCompanyInfo();
  }, []);

  return (
    <>
      <Container style={{ marginTop: '20px' }}>
        <Row className="justify-content-md-center">
          <Col>
            <NavBar />
          </Col>
        </Row>
        <Row className="justify-content-md-center" style={{ marginTop: '20px' }}>
          <Col>
            <h2 className="display-2">Welcome, {name}, from {company_name}!</h2>
          </Col>
        </Row>
        <Row className="justify-content-md-center" style={{ marginTop: '20px' }}>
          <Col>
            <h6 className="display-5">Company Information Card</h6>
          </Col>
        </Row>
        <Row className="justify-content-md-center" style={{ marginTop: '20px' }}>
          <Col>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                {company && (
                  <Card style={{ width: '18rem' }}>
                    <Card.Body>
                      <Card.Title>{company.name}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">ID: {company.id}</Card.Subtitle>
                      <Card.Text>
                        Industry: {company.industry}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                )}
                {status && <p>{status}</p>}
              </>
            )}
          </Col>
        </Row>
      </Container>
      <PageFooter />
    </>
  );
}

export default CompanyAgentHomePage;