import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import APICommunication from '../../api-communication/APICommunication';
import NavBar from '../../components/NavBar';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function ViewCompanies() {
    const [companies, setCompanies] = useState(null);
    const apiCommunication = new APICommunication(API_BASE_URL);

    const fetchData = async () => {
        try {
            const res = await apiCommunication.doCall('/admin/get-companies', 'GET');
            const jsonResponse = await res.json();
            setCompanies(jsonResponse.companies);
        } catch (e) {
            setCompanies(`Oops! An error occurred: ${e}`);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Container style={{ marginTop: '20px' }}>
                <NavBar />
            </Container>
            <div className="container" style={{ textAlign: 'center' }}>
                <div className="jumbotron" style={{
                    margin: '20px',
                    padding: '20px',
                    backgroundColor: '#f7f7f7',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'
                }}>
                    <h3 className="display-3">View Companies</h3>
                </div>
            </div>
            <div style={{ margin: '0 auto', width: '80%' }}>
                <table style={{
                    width: '100%',
                    margin: '20px auto',
                    borderCollapse: 'collapse',
                    textAlign: 'center',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'
                }}>
                    <thead>
                        <tr style={{
                            backgroundColor: '#4a90e2',
                            color: 'white'
                        }}>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Industry</th>
                        </tr>
                    </thead>
                    <tbody>
                        {companies && companies.map((company, index) => {
                            return (
                                <>
                                    <tr style={{ height: '2px', backgroundColor: 'blue' }}></tr> {/* Blue line above each new company */}
                                    <tr key={index} style={{
                                        backgroundColor: '#f7f7f7'
                                    }}>
                                        <td>{company.id}</td>
                                        <td>{company.name}</td>
                                        <td>{company.industry}</td>
                                    </tr>
                                    <tr>
                                        <th colSpan="3">
                                            <table
                                                style={{
                                                    width: '80%',
                                                    margin: '20px auto',
                                                    borderCollapse: 'collapse',
                                                    textAlign: 'center',
                                                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'
                                                }}>
                                                {company.listings && company.listings.length > 0 &&
                                                    <thead>
                                                        <tr style={{
                                                            backgroundColor: '#b3c7e6',
                                                            color: 'white',
                                                            fontSize: '12px',
                                                            fontWeight: 'normal'
                                                        }}>
                                                            <th>ID</th>
                                                            <th>Listing Name</th>
                                                            <th>Number Unsold</th>
                                                            <th>Type</th>
                                                            <th>Current Price</th>
                                                        </tr>
                                                    </thead>
                                                }
                                                {company.listings && company.listings.length > 0 && company.listings.map((listing, index_listing) => {
                                                    return (
                                                        <tbody key={index_listing}>
                                                            <tr style={{
                                                                backgroundColor: index_listing % 2 === 0 ? '#f7f7f7' : 'white'
                                                            }}>
                                                                <td style={{ fontSize: '12px', fontWeight: 'normal' }}>{listing.id}</td>
                                                                <td style={{ fontSize: '12px', fontWeight: 'normal' }}>{listing.name}</td>
                                                                <td style={{ fontSize: '12px', fontWeight: 'normal' }}>{listing.number}</td>
                                                                <td style={{ fontSize: '12px', fontWeight: 'normal' }}>{listing.type}</td>
                                                                <td style={{ fontSize: '12px', fontWeight: 'normal' }}>${(listing.current_price / 100).toFixed(2)}</td>
                                                            </tr>
                                                        </tbody>
                                                    )
                                                })}
                                            </table>
                                        </th>
                                    </tr>
                                </>
                            )
                        }
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default ViewCompanies;
