import React, { useEffect } from 'react';
//import '../App.css';
import { useState } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function App() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // For navigation
  const location = useLocation();
  
  // copied from workshop milestone 0
  const fetchData = async () => {
    setLoading(true);
    setData(null);
    const company = 'XYZ'
    try {
      const res = await fetch(
        `${API_BASE_URL}/create-listing`, {
          method: "GET",
          headers: {
            "Authorization": "Basic " + sessionStorage.getItem("BASIC_AUTHORIZATION")
        }
        }
      );
      if (res.status > 299) {
        setData(`woops! bad response status ${res.status} from API`);
        return;
      }
        setData(await res.text().then(data => JSON.parse(data)));
    } catch (e) {
      setData(`woops! an error occurred: ${e}`);
    } finally {
      setLoading(false);
    }
  };

  const handleDel = async (name, type) => {
    const formJson = {
      req: 'unlist',
      name: name,
      class: type
    };
    console.log(formJson);

    await fetch(`${API_BASE_URL}/create-listing`, {
      method: "POST",
      body: JSON.stringify(formJson),
      headers: {
        "Authorization": "Basic " + sessionStorage.getItem("BASIC_AUTHORIZATION")
      }
    });

    await fetchData();
  }

  // unpack from a JSON string, DUMMY DATa
  const listings = {
    data: [{
      listing: "ACCD",
      share_number: '123',
      share_class: 'Class_A',
      price: '$10',
    }]
  }

  // for indexing table rows
  var index = 1

  // Fetch data when component mounts or location changes
  useEffect(() => {
      fetchData();
  }, [location]);

  return (
    <>
      <div className="container">
        <div className="jumbotron">
          <h1>Welcome! Company XYZ user</h1>
          <h3>Listing Management</h3>
        </div>
      </div>
      <button><Link to="/create-listing">Create Listing</Link></button>
      <button onClick={fetchData}>Refresh</button>
      <div>
        <table>
          <thead>
            <tr>
              <th scope="col">Listing</th>
              <th scope="col">Shares class</th>
              <th scope="col">Price</th>
              <th scope="col">Shares number</th>
              <th scope="col"></th>{/* empty col */}
            </tr>
          </thead>
          <tbody>
            {
              data && data.map(row =>
                <tr key={index++}>
                  <td>{row.name}</td>
                  <td>{row.class}</td>
                  <td>{row.price}</td>
                  <td>{row.number}</td>
                  <td>
                    <button>Edit</button>
                    <button onClick={() => handleDel(row.name, row.class)}>Unlist</button>
                  </td>
                </tr>)
            }
          </tbody>
        </table>
      </div>
      <Outlet />
    </>
  );
}

export default App;
