import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from "prop-types";

// https://react-bootstrap.github.io/docs/components/navbar
// tabs: [{path: '/some-path', name: 'name-of-button'}]
function Navigation(props) {
  let {tabs} = props;
  const navigate = useNavigate(); // For navigation
  var homeTab = tabs.slice(0,1)[0];
  var itemList = tabs.slice(1).map((tab,key,arr) => {
      return (
        <Nav.Link key={key} onClick={()=>{
          if (tab.hasOwnProperty('callback')) {
            tab.callback();
          }
          navigate(tab['path']);
      }}>{tab['name']}</Nav.Link>
      );
  })
  return (
  <>
    <Navbar bg="light" data-bs-theme="light">
        <Container>
          <Navbar.Brand onClick={()=>navigate("/")}>Cloud Trading</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link onClick={() => {
              navigate(homeTab['path'])
              }}>{homeTab.name}</Nav.Link>
            {itemList}
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

Navigation.defaultProps = {
  tabs:[{path:"/", name:"Home", callback: ()=>{}}],
};

Navigation.propTypes = {
  tabs: PropTypes.array,
};


export default Navigation;