// HomePage.js
import React, {useState, useEffect} from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
//import '../App.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import PageFooter from 'components/PageFooter';
import Navigation from 'components/Navigation';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function LandingPage() {
  // const [data, setData] = useState(null);
  // const [status, setStatus] = useState(null)
  // const [loading, setLoading] = useState(false);
  // const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  // const navigate = useNavigate(); // For navigation
  // const location = useLocation();


  useEffect(() => {
    if (window.location.protocol === 'http:') {
      window.location.href = '/404';
    }
  }, []);


  // // copied from workshop milestone 0
  // // Fetch data when component mounts
  // const fetchData = async () => {
  //   setLoading(true);
  //   setData(null);
  //   setStatus(null);
  //   try {
  //     const res = await fetch(`${API_BASE_URL}/create-listing`);
  //     setData(await res.text().then(data => JSON.parse(data)));
  //   } catch (e) {
  //     setStatus('error');
  //     console.log("fetch error");
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  // for indexing table rows
  // var index = 1;

  // // Fetch data when component mounts or location changes
  // useEffect(() => {
  //   fetchData();
  // }, [location]);
  
  var tabs = [{path:"/", name:"Home"},{path:"/register", name:"Register"},{path:"/login", name:"Login"}]
  
  return (
      <>
      <Container>
        <Row>
            <Navigation tabs={tabs}/>
        </Row>
        <Row>
            <div style={{ height: '300px' }}>
            </div>
            <h1 className="display-1">Welcome to Cloud Trading</h1>
        </Row>
        <Row>
            <PageFooter />
        </Row>
      </Container>
      </>
  );
}

export default LandingPage;