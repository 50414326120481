class APICommunication {
    CONTENT_TYPE_APPLICATION_JSON = "application/json";
    STORAGE_ITEM_USER_ID = "user_id"
    STORAGE_ITEM_ROLE = "role"

    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }


    /**
     * 
     * @param {*} path api path
     * @param {*} method POST|GET
     * @param {*} data json object
     * @param {*} signal not used
     * @returns 
     */
    async doCall(path, method, data, signal) {

        const headers = {
            'Content-Type': this.CONTENT_TYPE_APPLICATION_JSON,
            Accept: this.CONTENT_TYPE_APPLICATION_JSON,
        };

        let body;
        if (data) {
            body = JSON.stringify(data);
        }
        const res = await fetch(
            `${this.baseUrl}${path}`,
            {
                method: method,
                headers: headers,
                body: body,
                credentials: 'include'
            },
        );

        // //debugging
        // console.log(res);

        // if the unauthorized error is returned (session expired), refresh the token and try again
        if (res.status === 401 || res.status === 403) {
            if (!sessionStorage.getItem('alertShown')) {
                // Show a popup message to the user
                alert('Session is expired or invalid. Please log in again to continue.');

                // Set a flag in the session storage to not show the alert again
                sessionStorage.setItem('alertShown', 'true');
            }

            // Redirect to the login page
            window.location.href = '/login';
            return null;
        } else {
            sessionStorage.removeItem('alertShown');
        }
        if (res.status > 299) {
            throw new Error(`expecting success from API for ${method} ${path} but response was status ${res.status}: ${res.statusText}`);
        }

        return res;
    }

    async login(username, password, signal) {
        const res = await this.doCall('/account/login', 'POST',
            { username: username, password: password },
            signal);

        const data = await res.json();

        this.setLoginDataInStorage(data);

        return data;
    }

    async logout() {
        await this.doCall('/account/logout', 'POST', { user_id: parseInt(sessionStorage.getItem("user_id")) });
        sessionStorage.removeItem(this.STORAGE_ITEM_USER_ID);
        sessionStorage.removeItem(this.STORAGE_ITEM_ROLE);
    }

    setLoginDataInStorage(data) {
        sessionStorage.setItem(this.STORAGE_ITEM_USER_ID, data['user_id']);
        sessionStorage.setItem(this.STORAGE_ITEM_ROLE, data['role']);
        if (data['role'] == "CUSTOMER") {
            sessionStorage.setItem("name", data['name']);
        } else if (data['role'] == "ADMIN") {
            sessionStorage.setItem("name", data['name']);
        } else if (data['role'] == "COMPANY_AGENT") {
            sessionStorage.setItem("name", data["name"]);
            sessionStorage.setItem("industry", data["industry"]);
            sessionStorage.setItem("company_name", data["company_name"]);
        }
    }
}

export default APICommunication;