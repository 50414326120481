import React from 'react';
import Card from 'react-bootstrap/Card';

function PageFooter() {
    return(
    <>
        <div style={{height: '500px'}}></div>
        <Card className="m-4">
            <p className="m-4">&copy; Cloud 2023 Trading System.</p>
        </Card>
    </>
    );
}

export default PageFooter;