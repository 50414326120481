import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate, useLocation } from 'react-router-dom';

// https://react-bootstrap.github.io/docs/components/navbar
function LoginNavigation() {
  const navigate = useNavigate(); // For navigation
  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">Cloud Trading</Navbar.Brand>
          <Container className="d-inline-flex justify-content-between">
            <Button className="btn btn-primary" onClick={() => navigate('/')}>Home</Button>
            <button className="btn btn-secondary" onClick={() => navigate('/customer')}>Customer</button>
            <button className="btn btn-secondary" onClick={() => navigate('/admin')}>Admin</button>
            <button className="btn btn-secondary" onClick={() => navigate('/company')}>Agent</button>
          </Container>
        </Container>
      </Navbar>
    </>
  );
}

export default LoginNavigation;