import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function ChangePassword() {
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState({ email: "", oldPassword: "", newPassword: "" });

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const email = params.get('email');
        if (email) {
            setState((prev) => ({ ...prev, email }));
        }
    }, [location.search]);

    async function handleChangePassword(e) {
        e.preventDefault();

        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());

        const response = await fetch(`${API_BASE_URL}/change-password`, {
            method: form.method, body: JSON.stringify(formJson),
            headers: {
                "Authorization": "Basic " + sessionStorage.getItem("BASIC_AUTHORIZATION")
            }
            // method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },
            // body: JSON.stringify(formJson)
        });

        const result = await response.json();

        if (result.status === 'success') {
            alert("Password successfully changed!");
            navigate('/login');
        } else {
            alert(result.message || "Failed to change password.");
        }
    }

    return (
        <>
            <button><Link to="/">Home</Link></button>
            <section>
                <form method="post" onSubmit={handleChangePassword}>
                    <h2>Change Password</h2>
                    <p>
                        <label htmlFor="oldPassword">
                            <span>Old Password:</span>
                        </label>
                        <input
                            type="password"
                            id="oldPassword"
                            name="oldPassword"
                            value={state.oldPassword}
                            onChange={(e) => {
                                setState(oldState => ({ ...oldState, oldPassword: e.target.value }))
                            }}
                        />
                    </p>
                    <p>
                        <label htmlFor="newPassword">
                            <span>New Password:</span>
                        </label>
                        <input
                            type="password"
                            id="newPassword"
                            name="newPassword"
                            value={state.newPassword}
                            onChange={(e) => {
                                setState(oldState => ({ ...oldState, newPassword: e.target.value }))
                            }}
                        />
                    </p>
                    <p>
                        <input type="submit" value="Change Password" />
                    </p>
                    <input type="hidden" name="email" value={state.email} />
                </form>
            </section>
        </>
    );
}

export default ChangePassword;
