import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import React from 'react'
import Listings from "pages/Listings";
import Login from "pages/Login";
import LandingPage from "./pages/LandingPage";
import ForgotPassword from "./components/ForgotPassword";
import ChangePassword from "./components/ChangePassword";
import AdminHomePage from "./pages/Admin/AdminHomePage";
import CompanyAgentHomePage from "./pages/CompanyAgent/CompanyAgentHomePage"
import CustomerHomePage from "./pages/Customer/CustomerHomePage"
import Register from "./pages/Register"
import TokenTest from "pages/TokenTest"
import BuyShare from "pages/Customer/BuyShare";
import Portfolio from "pages/Customer/Portfolio";
import SellShare from "pages/Customer/SellShare";
import CreateListing from "pages/CompanyAgent/CreateListing";
import EditListing from "pages/CompanyAgent/EditListing";
import ViewListings from "pages/CompanyAgent/ViewListings";
import ViewCustomers from "pages/Admin/ViewCustomers";
import ViewCompanies from "pages/Admin/ViewCompanies";
import EditCompanies from "pages/Admin/EditCompanies";
import NotFoundPage from "pages/NotFoundPage";
import PrivateRoute from "./components/PrivateRoute";

// https://www.w3schools.com/react/react_router.asp
// https://blog.logrocket.com/react-router-dom-tutorial-examples/
export default function AppRouter() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="login" element={<Login />} />
                    <Route path="register" element={<Register />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                    <Route path="change-password" element={<ChangePassword />} />
                    <Route path="token-test" element={<TokenTest />} />

                    <Route path="admin" element={<PrivateRoute roles={["ADMIN"]} element={<AdminHomePage />} />} />
                    <Route path="/admin/view-customers" element={<PrivateRoute roles={["ADMIN"]} element={<ViewCustomers />} /> } />
                    <Route path="/admin/view-companies" element={<PrivateRoute roles={["ADMIN"]} element={<ViewCompanies />} /> } />
                    <Route path="/admin/edit-companies" element={<PrivateRoute roles={["ADMIN"]} element={<EditCompanies />} /> } />

                    <Route path="/customer" element={<PrivateRoute roles={["CUSTOMER"]} element={<CustomerHomePage />} /> } />
                    <Route path="/customer/buy" element={<PrivateRoute roles={["CUSTOMER"]} element={<BuyShare />} /> } />
                    <Route path="/customer/portfolio" element={<PrivateRoute roles={["CUSTOMER"]} element={<Portfolio />} /> } />
                    <Route path="/customer/sell" element={<PrivateRoute roles={["CUSTOMER"]} element={<SellShare />} /> } />

                    <Route path="/company_agent" element={<PrivateRoute roles={["COMPANY_AGENT"]} element={<CompanyAgentHomePage />} /> } />
                    <Route path="/company/view-listings" element={<PrivateRoute roles={["COMPANY_AGENT"]} element={<ViewListings />} /> } />
                    <Route path="/company/create-listing" element={<PrivateRoute roles={["COMPANY_AGENT"]} element={<CreateListing />} /> } />
                    <Route path="/company/edit-listing" element={<PrivateRoute roles={["COMPANY_AGENT"]} element={<EditListing />} /> } />

                    <Route path="listings" element={<PrivateRoute roles={["ADMIN"]} element={<Listings />} /> } />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}
