import React, { useState } from 'react';
import APICommunication from '../api-communication/APICommunication';


function TokenTest() {
    console.log(process.env.REACT_APP_API_BASE_URL)
    const api = new APICommunication(process.env.REACT_APP_API_BASE_URL)
    return (
        <>
            <button onClick={() => api.login("testagent1", "testpassword")}>Login</button>
            <button onClick={() => api.logout()}>Logout</button>
            <button onClick={() => api.refresh()}>Refresh Token</button>
            <button onClick={() => api.doCall("/company-agent/get-company", "GET")}>get company</button>
        </>
    )
}

export default TokenTest;