import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoginNavigation from "../components/LoginNavigation"
import PageFooter from 'components/PageFooter';
import Navigation from 'components/Navigation';
import Form from 'react-bootstrap/Form';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Register() {
    const navigate = useNavigate();
    const [state, setState] = useState({ name:"", role: "CUSTOMER", email:"", username: "", password: "", password2:"", company_name:"" });

    async function handleSubmit(e) {
        e.preventDefault();

        if (state.username == "") {
            alert("username cannot be empty!");
            return;
        }

        if (state.password == "") {
            alert("please enter password!");
            return;
        }

        if (state.password != state.password2) {
            alert("please enter same password!");
            return;
        }

        if (state.password.length < 8) {
            alert("please enter a password with at least 8 characters!");
            return;
        }

        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());

        fetch(`${API_BASE_URL}/account/register`, {
            method: "POST", body: JSON.stringify(formJson),
            // method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },
            // body: JSON.stringify(formJson)
        })
        .then(response => response.json())
        .then(result => {
            alert(`${result.status}: ${result.reason}`);
            if (result.status == "success"){
                navigate("/");
            }
        })
        .catch(e=>{
            alert("registration failed");
        });
    }

    return (
        <>
        <Container>
            <Row>
                <Navigation />
            </Row>
            <Row>
                <div style={{height: '100px'}}></div>
                <section className="d-flex justify-content-center m-2">
                    <Form method="post" onSubmit={handleSubmit}>
                        <Form.Label>Role</Form.Label>
                            <Form.Select name="role" value={state.role} onChange={
                                (e) => {
                                    setState(oldState => ({...oldState, "role": e.target.value}))
                                }
                            }>
                                <option value="" disabled>Select Type</option>
                                <option value="CUSTOMER">Customer</option>
                                <option value="COMPANY_AGENT">Company Agent</option>
                        </Form.Select>
                        {(state.role == "CUSTOMER" || state.role == "COMPANY_AGENT") &&
                        <Form.Group  className="mb-3" controlId="formBasicText">
                        <Form.Label>Name</Form.Label>
                            <Form.Control type="text" id="name" name="name"
                                   value={state.name} onChange={
                                (e) => { setState(oldState => ({ ...oldState, "name": e.target.value })) }
                            } />
                        </Form.Group>
                        }
                        {state.role == "COMPANY_AGENT" &&
                        <Form.Group  className="mb-3" controlId="formBasicText">
                        <Form.Label>Company Name</Form.Label>
                            <Form.Control type="text" id="company_name" name="company_name"
                                   value={state.company_name} onChange={
                                (e) => { setState(oldState => ({ ...oldState, "company_name": e.target.value })) }
                            } />
                        </Form.Group>
                        }
                        <Form.Group  className="mb-3" controlId="formBasicText">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" id="email" name="email"
                                   value={state.email} onChange={
                                (e) => { setState(oldState => ({ ...oldState, "email": e.target.value })) }
                            } />
                        </Form.Group>
                        <Form.Group  className="mb-3" controlId="formBasicText">
                        <Form.Label>Username</Form.Label>
                            <Form.Control type="text" id="username" name="username"
                                   value={state.username} onChange={
                                (e) => { setState(oldState => ({ ...oldState, "username": e.target.value })) }
                            } />
                        </Form.Group>
                        <Form.Group  className="mb-3" controlId="formBasicText">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" id="password" name="password"
                                   value={state.password} onChange={
                                (e) => { setState(oldState => ({ ...oldState, "password": e.target.value })) }
                            } />
                        </Form.Group>
                        <Form.Group  className="mb-3" controlId="formBasicText">
                            <Form.Label>Password(again)</Form.Label>
                            <Form.Control type="password" id="password2" name="password2"
                                   value={state.password2} onChange={
                                (e) => { setState(oldState => ({ ...oldState, "password2": e.target.value })) }
                            } />
                        </Form.Group>
                        <div className="d-flex justify-content-center">
                            <button type="submit" value="Register" className="btn btn-primary">Register</button>
                        </div>
                    </Form>
                </section>
            </Row>
            <Row>
                <PageFooter />
            </Row>
        </Container>
        </>
    );
}

export default Register;
