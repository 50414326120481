import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBar from 'components/NavBar';
import PageFooter from 'components/PageFooter';
import Navigation from 'components/Navigation';

function AdminHomePage() {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate(); // For navigation
  const location = useLocation();

  // const fetchData = async () => {
  //   setLoading(true);
  //   setData(null);
  //   setStatus(null);
  //   try {
  //     const res = await fetch(`${API_BASE_URL}/create-listing`);
  //     setData(await res.text().then(data => JSON.parse(data)));
  //   } catch (e) {
  //     setStatus('error');
  //     console.log("fetch error");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // // Fetch data when component mounts or location changes
  // useEffect(() => {
  //   fetchData();
  // }, [location]);

  return (
    <>
      <Container style={{ marginTop: '20px' }}>
        <Row className="justify-content-md-center">
          <Col>
            <NavBar />
          </Col>
        </Row>
        <Row className="justify-content-md-center" style={{ marginTop: '20px' }}>
          <Col>
            <div style={{height: '200px'}}></div>
            <h2 className="display-2">Welcome, Admin!</h2>
          </Col>
        </Row>
        <Row className="justify-content-md-center" style={{ marginTop: '20px' }}>
          <Col>
            <PageFooter />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AdminHomePage;
