import React from 'react';

const NotFoundPage = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.header}>404 - Page Not Found</h1>
            <p style={styles.paragraph}>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable. You may need to log in to access certain pages.</p>
            <HomeLink />
        </div>
    );
}

const HomeLink = () => {
    const role = sessionStorage.getItem('role');
    console.log(role);
    let homepage;

    switch (role) {
        case 'ADMIN':
            homepage = '/admin';
            break;
        case 'COMPANY_AGENT':
            homepage = '/company_agent';
            break;
        case 'CUSTOMER':
            homepage = '/customer';
            break;
        default:
            homepage = '/';
    }

    return <a href={homepage} style={styles.link}>Go to Homepage</a>;
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        padding: '20px'
    },
    header: {
        color: 'red',
        fontSize: '36px',
        marginBottom: '20px'
    },
    paragraph: {
        marginBottom: '20px',
        color: 'grey'
    },
    link: {
        color: 'blue',
        textDecoration: 'underline'
    }
};

export default NotFoundPage;
