import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import APICommunication from '../../api-communication/APICommunication';
import NavBar from '../../components/NavBar';
import Form from "react-bootstrap/Form";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function BuyShare() {
    const [data, setData] = useState(null);
    const [quantities, setQuantities] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); // For navigation
    const location = useLocation();
    const [searchByCompany, setSearchByCompany] = useState('');
    const [searchByIndustry, setSearchByIndustry] = useState('');
    const [searchByListing, setSearchByListing] = useState('');
    const [searchByPriceMin, setSearchByPriceMin] = useState('');
    const [searchByPriceMax, setSearchByPriceMax] = useState('');


    const apiCommunication = new APICommunication(API_BASE_URL);

    const fetchData = async () => {
        setSearchByCompany('');
        setSearchByIndustry('');
        setSearchByListing('');
        setSearchByPriceMin('');
        setSearchByPriceMax('');
        setLoading(true);
        setData(null);
        setQuantities({});
        try {
            const res = await apiCommunication.doCall('/customer/get-listings', 'GET');
            const jsonResponse = await res.json();
            setData(jsonResponse.listings);
            // console.log(jsonResponse);
        } catch (e) {
            setData(`woops! an error occurred: ${e}`);
        } finally {
            setLoading(false);
        }
    };

    const handleBuy = async (name) => {
        const payload = {
            listing_name: name,
            quantity: quantities[name] || 0
        };

        try {
            const res = await apiCommunication.doCall('/customer/buy-share', 'POST', payload);
            const data = await res.json();
            console.log("Success:", data);
            if (data.status === "success") {
                window.alert("Shares purchased successfully!");
                window.location.reload();
            } else {
                window.alert(data.message || "An error occurred.");
            }
        } catch (e) {
            console.log(`Failed to buy shares: ${e}`);
        }
    };

    const handleQuantityChange = (name, value, maxQuantity) => {
        const quantity = parseInt(value, 10);
        if (quantity >= 0 && quantity <= maxQuantity) {
            setQuantities({ ...quantities, [name]: quantity });
        }
    };

    const handleSearch = async () => {
        if (!searchByCompany && !searchByIndustry && !searchByListing && !searchByPriceMin && !searchByPriceMax) {
            fetchData();
            return;
        }
        let filteredData = [...data];
        try {
            if (searchByCompany) {
                console.log("searchByCompany");
                const payload = {
                    search_by: 'company',
                    keywords: searchByCompany,
                };

                const res = await apiCommunication.doCall('/customer/search-listings', 'POST', payload);
                const jsonResponse = await res.json();

                setData(jsonResponse.listings);
            }
            else if (searchByIndustry) {
                const payloadIndustry = {
                    search_by: 'industry',
                    keywords: searchByIndustry,
                };
                const res = await apiCommunication.doCall('/customer/search-listings', 'POST', payloadIndustry);
                const jsonResponse = await res.json();
                setData(jsonResponse.listings);
            }
            else if (searchByListing) {
                const payloadIndustry = {
                    search_by: 'listing',
                    keywords: searchByListing,
                };
                const res = await apiCommunication.doCall('/customer/search-listings', 'POST', payloadIndustry);
                const jsonResponse = await res.json();
                setData(jsonResponse.listings);
            }
            else if (searchByPriceMin || searchByPriceMax) {
                filteredData = filteredData.filter(row => {
                    return (searchByPriceMin === '' || row.current_price / 100 >= searchByPriceMin) &&
                        (searchByPriceMax === '' || row.current_price / 100 <= searchByPriceMax);
                });
                setData(filteredData);
            }
            setSearchByCompany('');
            setSearchByIndustry('');
            setSearchByListing('');
            setSearchByPriceMin('');
            setSearchByPriceMax('');
        } catch (e) {
            console.log(`Failed to search listings: ${e}`);
        }
    };

    function clearKeywordSearchFields() {
        setSearchByCompany('');
        setSearchByIndustry('');
        setSearchByListing('');
    }

    function clearPriceSearchFields() {
        setSearchByPriceMin(0);
        setSearchByPriceMax(0);
    }

    function clearAllSearchFields() {
        clearKeywordSearchFields();
        clearPriceSearchFields();
    }


    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Container style={{ marginTop: '20px' }}>
                <NavBar />
            </Container>
            <div className="container" style={{ textAlign: 'center' }}>
                <div className="jumbotron" style={{ margin: '20px', padding: '20px', backgroundColor: '#f2f2f2' }}>
                    <h3 className="display-3">Share Purchase</h3>
                </div>
            </div>
            <div style={{ textAlign: 'center' }}>
                <button className="btn btn-primary" style={{ margin: '10px', padding: '10px' }} onClick={fetchData}>Refresh</button>
            </div>
            <div style={{ margin: '0 auto', width: '80%' }}>
                <table style={{
                    width: '100%',
                    margin: '20px auto',
                    borderCollapse: 'collapse',
                    textAlign: 'center'
                }}>
                    <thead>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th><Form.Label style={{ fontSize: "12px", marginRight: "-40px" }}>Min Price:</Form.Label></th>
                        <th> <Form.Control
                            className="text-center"
                            type="number"
                            min='0'
                            step='1'
                            placeholder=""
                            value={searchByPriceMin}
                            onChange={(e) => { setSearchByPriceMin(e.target.value) }}
                        /></th>
                    </thead>
                    <thead>
                        <tr>
                            <th><Form.Control className="text-center" type="text" placeholder="Search by Company" value={searchByCompany} onChange={(e) => { clearAllSearchFields(); setSearchByCompany(e.target.value) }} /></th>
                            <th><Form.Control className="text-center" type="text" placeholder="Search by Listing" value={searchByListing} onChange={(e) => { clearAllSearchFields(); setSearchByListing(e.target.value) }} /></th>
                            <th><Form.Control className="text-center" type="text" placeholder="Search by Industry" value={searchByIndustry} onChange={(e) => { clearAllSearchFields(); setSearchByIndustry(e.target.value) }} /></th>
                            <th><Form.Label style={{ fontSize: "12px", marginRight: "-40px" }}>Max Price:</Form.Label>
                            </th>
                            <th><Form.Control
                                className="text-center"
                                type="number"
                                min='0'
                                step='1'
                                placeholder=""
                                value={searchByPriceMax}
                                onChange={(e) => { setSearchByPriceMax(e.target.value) }}
                            />
                            </th>
                            <th>
                                <button className="btn btn-primary" onClick={handleSearch}>Search</button>
                            </th>
                        </tr>
                        <tr><br></br></tr>
                        <tr>
                            <th scope="col">Company Name</th>
                            <th scope="col">Listing Name</th>
                            <th scope="col">Industry</th>
                            <th scope="col">Shares Number</th>
                            <th scope="col">Current Price ($)</th>
                            <th scope="col">Quantity to Buy</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.filter(row => row.number !== 0).map((row, index) =>
                            <tr key={index}>
                                <td>{row.company_name}</td>
                                <td>{row.name}</td>
                                <td>{row.industry}</td>
                                <td>{row.number}</td>
                                <td>${(row.current_price / 100).toFixed(2)}</td>
                                <td className="d-flex">
                                    <Form.Control
                                        type="number"
                                        min="1"
                                        max={row.number}
                                        placeholder="1"
                                        value={quantities[row.name] || ''}
                                        onChange={(e) => handleQuantityChange(row.name, e.target.value, row.number)}
                                    />
                                </td>
                                <td>
                                    <button className="ms-4 btn btn-primary" onClick={() => handleBuy(row.name)}>Buy</button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default BuyShare;



