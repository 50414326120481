import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import APICommunication from '../../api-communication/APICommunication';
import NavBar from '../../components/NavBar';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function EditCompanies() {
    const [companies, setCompanies] = useState(null);
    const [newCompanyName, setNewCompanyName] = useState("");
    const [newCompanyIndustry, setNewCompanyIndustry] = useState("");
    const [editMode, setEditMode] = useState(null);

    const apiCommunication = new APICommunication(API_BASE_URL);

    const fetchData = async () => {
        try {
            const res = await apiCommunication.doCall('/admin/get-companies', 'GET');
            const jsonResponse = await res.json();
            setCompanies(jsonResponse.companies);
        } catch (e) {
            setCompanies(`Oops! An error occurred: ${e}`);
        }
    };

    const addCompany = async () => {
        if (newCompanyName != "" && newCompanyIndustry!="") {
            const payload = { name: newCompanyName, industry: newCompanyIndustry };
            await apiCommunication.doCall('/admin/add-company', 'POST', payload);
            fetchData();
            // Clear text boxes
            setNewCompanyName("");
            setNewCompanyIndustry("");
        } else {
            alert("company name and industry name cannot be empty!");
        }
    };

    const editCompany = async (id, name, industry) => {
        if (name!="" && industry!=""){
            const payload = { id, name, industry };
            await apiCommunication.doCall('/admin/edit-company', 'POST', payload);
            fetchData();
            setEditMode(null); // Exit edit mode
        } else {
            alert("name and industry cannot be empty!");
        }
    };

    const removeCompany = async (id) => {
        if (window.confirm("do you really want to delete this company?")) {
            const payload = { id };
            await apiCommunication.doCall('/admin/remove-company', 'POST', payload);
            fetchData();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Container style={{ marginTop: '20px' }}>
                <NavBar />
            </Container>
            <div className="container" style={{ textAlign: 'center' }}>
                <div className="jumbotron" style={{
                    margin: '20px',
                    padding: '20px',
                    backgroundColor: '#f7f7f7',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'
                }}>
                    <h3 className="display-3">Edit Companies</h3>
                </div>
            </div>
            <div style={{ margin: '0 auto', width: '80%' }}>
                <table style={{
                    width: '100%',
                    margin: '20px auto',
                    borderCollapse: 'collapse',
                    textAlign: 'center',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'
                }}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Industry</th>
                            <th></th>
                        </tr>
                        <tr>
                            <br></br>
                        </tr>
                        <tr>
                            <th></th>
                            <th>
                                <input
                                    type="text"
                                    placeholder="New Company Name"
                                    value={newCompanyName}
                                    onChange={(e) => setNewCompanyName(e.target.value)}
                                />
                            </th>
                            <th>
                                <input
                                    type="text"
                                    placeholder="New Industry"
                                    value={newCompanyIndustry}
                                    onChange={(e) => setNewCompanyIndustry(e.target.value)}
                                />
                            </th>
                            <th>
                                <Button style={{ color: "white", background: "green" }} size="md" onClick={addCompany}>Add Company</Button>
                            </th>
                        </tr>
                        <tr> <br></br>
                        </tr>
                    </thead>
                    <tbody>
                        {companies && companies.map((company, index) => (
                            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f7f7f7' : 'white' }}>
                                <td>{company.id}</td>
                                <td>
                                    {editMode === company.id ? (
                                        <input type="text" defaultValue={company.name} onChange={(e) => company.name = e.target.value} />
                                    ) : (
                                        company.name
                                    )}
                                </td>
                                <td>
                                    {editMode === company.id ? (
                                        <input type="text" defaultValue={company.industry} onChange={(e) => company.industry = e.target.value} />
                                    ) : (
                                        company.industry
                                    )}
                                </td>
                                <td>
                                    {editMode === company.id ? (
                                        <>
                                        <div className="d-flex justify-content-center">
                                            <div className="m-2">
                                            <Button variant="success" size="md" onClick={() => editCompany(company.id, company.name, company.industry)}>Confirm</Button>
                                            </div>
                                            <div className="m-2">
                                            <Button style={{ background: "red" }} size="md" onClick={() => setEditMode(null)}>Cancel</Button>
                                            </div>
                                        </div>
                                        </>
                                    ) : (
                                        <>
                                        <div className="d-flex justify-content-center">
                                        <div className="m-2 ">
                                            <Button variant="primary" size="md" onClick={() => setEditMode(company.id)}>Edit</Button>
                                        </div>
                                        <div className="m-2">
                                            <Button variant="danger" size="md" onClick={() => removeCompany(company.id)}>Remove</Button>
                                        </div>
                                        </div>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default EditCompanies;
