import { Navigate } from 'react-router-dom';
import React from 'react';
import PropTypes from "prop-types";

const PrivateRoute = ({ roles, element }) => {
    const role = sessionStorage.getItem('role');

    return roles.includes(role)
        ? element
        : <Navigate to="/404" />;
};

PrivateRoute.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    element: PropTypes.element.isRequired,
};

export default PrivateRoute;
