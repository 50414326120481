import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import APICommunication from '../../api-communication/APICommunication';
import NavBar from '../../components/NavBar';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function ViewListings() {
    const [listings, setListings] = useState(null);
    const apiCommunication = new APICommunication(API_BASE_URL);
    const company_name = sessionStorage.getItem("company_name");

    const fetchData = async () => {
        try {
            const res = await apiCommunication.doCall('/company-agent/get-listings', 'GET');
            const jsonResponse = await res.json();
            setListings(jsonResponse.listings);
        } catch (e) {
            setListings(`Oops! An error occurred: ${e}`);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Container style={{ marginTop: '20px' }}>
                <NavBar />
            </Container>
            <div className="container" style={{ textAlign: 'center' }}>
                <div className="jumbotron" style={{
                    margin: '20px',
                    padding: '20px',
                    backgroundColor: '#f7f7f7',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'
                }}>
                    <h3 className="display-3">View Listings</h3>
                </div>
            </div>
            <div style={{ margin: '0 auto', width: '80%' }}>
                <table style={{
                    width: '100%',
                    margin: '20px auto',
                    borderCollapse: 'collapse',
                    textAlign: 'center',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'
                }}>
                    <thead>
                        <tr style={{
                            backgroundColor: '#4a90e2',
                            color: 'white'
                        }}>
                            <th>Name</th>
                            <th>Number</th>
                            <th>Current Price</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listings && listings.map((listing, index) => (
                            <tr key={index} style={{
                                backgroundColor: index % 2 === 0 ? '#f7f7f7' : 'white'
                            }}>
                                <td>{listing.name}</td>
                                <td>{listing.number}</td>
                                <td>${(listing.current_price/100).toFixed(2)}</td>
                                <td>{listing.type}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default ViewListings;
