import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import APICommunication from '../../api-communication/APICommunication';
import NavBar from '../../components/NavBar';
import Form from 'react-bootstrap/Form';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function CreateListing() {
    const [data, setData] = useState({
        name: '',
        number: 0,
        type: '',
        current_price: 0
    });
    const [message, setMessage] = useState(null);

    const apiCommunication = new APICommunication(API_BASE_URL);

    const company_name = sessionStorage.getItem("company_name");

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleCreateListing = async () => {
        try {
            const res = await apiCommunication.doCall('/company-agent/create-listing', 'POST', data);
            const jsonResponse = await res.json();
            setMessage(jsonResponse.message);

            console.log(jsonResponse);

            if (jsonResponse.status === 'success') {
                alert("A new listing has been created!");
                navigate("../company/view-listings");
            }
        } catch (e) {
            setMessage(`Oops! An error occurred: ${e}`);
        }
    };

    return (
        <>
            <Container style={{ marginTop: '20px', fontFamily: 'Arial, sans-serif' }}>
                <NavBar />
            </Container>
            <div className="container"> {/*style={{ textAlign: 'center' }}>*/}
                <div className="jumbotron" style={{ margin: '20px', padding: '20px', backgroundColor: '#f2f2f2', textAlign: 'center' }}>
                    <h3 className="display-3">Create New Listing</h3>
                    {message && <p>{message}</p>}
                </div>
                <div style={{ margin: '0 auto', width: '60%', border: '1px solid #ccc', padding: '20px', borderRadius: '8px' }}>
                    <Form>
                        <div style={{ marginBottom: '15px' }}>
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="name" minlength='1' value={data.name} onChange={handleInputChange} style={{ marginLeft: '10px' }} />
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                        <Form.Label>Number</Form.Label>
                            <Form.Control type="number" name="number" min='1' value={data.number} onChange={handleInputChange} style={{ marginLeft: '10px' }} />
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <Form.Label>Type</Form.Label>
                            <Form.Select name="type" value={data.type} onChange={handleInputChange} style={{ marginLeft: '10px' }}>
                                <option value="" disabled>Select Type</option>
                                <option value="CLASS_A">CLASS_A</option>
                                <option value="CLASS_B">CLASS_B</option>
                                <option value="CLASS_C">CLASS_C</option>
                            </Form.Select>
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <Form.Label>Current Price</Form.Label>
                            <Form.Control type="number" name="current_price" value={data.current_price} onChange={handleInputChange} style={{ marginLeft: '10px' }} />
                        </div>
                        {/*<button type="button" onClick={handleCreateListing} style={{ backgroundColor: '#007bff', color: 'white', padding: '10px 20px', borderRadius: '4px' }}>*/}
                        <div className="d-flex justify-content-center">
                        <button type="button" onClick={handleCreateListing} className="btn btn-primary">
                            Create
                        </button>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default CreateListing;
